import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import Layout from '../components/layout'
import Icon from '../components/icon'
import { logo } from '../components/constants'

const Content = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  min-height: 50vh;
  max-width: 960px;
  margin: 0 auto;
  padding: 1.5rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const NotFoundPage = () => (
  <Layout>
    <Content>
      <div>
        <Link
          to="/"
          title="No Class Tattoo Studio"
        >
          <Icon
            icon={logo}
            colour="#FAF0E6"
            size="240"
          />
        </Link>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... check your URL is correct.</p>
      </div>
    </Content>
  </Layout>
)

export default NotFoundPage
